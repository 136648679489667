import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "@components/shared/hero";
import { Query } from "@graphql-types";

const HomeHero = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        hero {
          content {
            _rawRichTextContent
          }
          links {
            url
            newwindow
            linktext
            internallink
            _key
          }
        }
      }
    }
  `);

  return <Hero {...sanityHomePage?.hero} />;
};

export default HomeHero;
