import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { Query } from "@graphql-types";
import { Container, H2, VerticalSeparator } from "@util/standard";
import { BlocksContent, Button, Image, Link } from "@sub";
import {
  assets,
  colors,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;
  margin-top: 90px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 65px;
  }
`;

const AboutUs = styled(Container)`
  width: 80%;
  margin: auto;
  position: relative;
  justify-content: flex-end;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const TextContainer = styled(Container)`
  flex-direction: column;
  margin: 50px auto 120px 645px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    margin: 100px auto 120px 545px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 0 40px 0;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`;

const ImageContainer = styled(Container)<{}>`
  z-index: 2;
  height: 670px;
  width: 545px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  left: 0;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    height: 570px;
    width: 445px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    position: relative;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 405px;
    width: 100%;
  }
`;

const BlockContainer = styled(Container)<{ maxWidth?: number }>`
  max-width: ${(props) => props.maxWidth ?? 350}px;
  flex-direction: column;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 80%;
  }
`;

const TelecomContainer = styled(Container)`
  position: relative;
  width: 100%;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  height: 630px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 430px;
  }
`;

const TelecomContentContainer = styled(Container)`
  z-index: 1;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
  }
`;

const Backdrop = styled.div`
  left: 0;
  position: absolute;
  width: 90%;
  background-color: ${colors.lightBlue};
  height: 630px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: -350px;
    height: 780px;
  }
`;

const Img = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 20px 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 40px;
    height: 40px;
  }
`;

const AboutUsBlock = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        aboutUs {
          title
          link {
            url
            newwindow
            linktext
            internallink
          }
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
        }
        telecom {
          link {
            url
            newwindow
            linktext
            internallink
          }
          title
          subtitle
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <AboutUs>
        <TextContainer>
          <Container>
            <VerticalSeparator />
            <H2 margin="0">{sanityHomePage?.aboutUs?.title}</H2>
          </Container>
          <BlockContainer>
            <BlocksContent
              blocks={sanityHomePage?.aboutUs?.description?._rawColumnContent}
            />
            <Link
              isUnderlined
              {...sanityHomePage?.aboutUs?.link}
              color="darkBlue"
            />
          </BlockContainer>
        </TextContainer>
        <ImageContainer>
          <Image
            fluid={sanityHomePage?.aboutUs?.image?.asset?.fluid as FluidObject}
            isBackground
            backgroundStyle={{
              width: "100%",
            }}
          />
        </ImageContainer>
      </AboutUs>
      <TelecomContainer>
        <Backdrop />
        <TelecomContentContainer>
          <Img alt="telecom-icon" src={assets.telecom} />
          <VerticalSeparator dimensions={{ height: "80px", width: "25px" }} />
          <BlockContainer maxWidth={420}>
            <H2 margin="0">{sanityHomePage?.telecom?.title}</H2>
            <H2 margin="0" fontWeight="normal">
              {sanityHomePage?.telecom?.subtitle}
            </H2>
            <BlocksContent
              blocks={sanityHomePage?.telecom?.description?._rawColumnContent}
            />
            <Button
              theme="base"
              text={sanityHomePage?.telecom?.link?.linktext ?? ""}
              linkTo={sanityHomePage?.telecom?.link?.url ?? "/"}
              dimensions={{ height: "46px" }}
              margin="20px 0 0 0"
            />
          </BlockContainer>
        </TelecomContentContainer>
      </TelecomContainer>
    </Wrapper>
  );
};

export default AboutUsBlock;
