import * as React from "react";
import { graphql, PageProps } from "gatsby";

import HomeHero from "@components/home/homeHero";
import AboutUsBlock from "@components/home/aboutUsBlock";
import ConsultationsBlock from "@components/home/consultationsBlock";
import ServiceProvidersBlock from "@components/home/serviceProvidersBlock";
import { Query } from "@graphql-types";
import SEO from "@components/shared/seo";

interface Props extends PageProps {
  data: Query;
}

export default function Home({ data }: Props) {
  const { sanityHomePage } = data;

  return (
    <div>
      <SEO seoData={sanityHomePage?.seo} />
      <HomeHero />
      <AboutUsBlock />
      <ConsultationsBlock />
      <ServiceProvidersBlock />
    </div>
  );
}

export const query = graphql`
  query HomeQuery {
    sanityHomePage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
