import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { FluidObject } from "gatsby-image";

import { BlocksContent, Image, Link } from "@sub";
import { A, Container, GridContainer, H2, H3, Separator } from "@util/standard";
import { colors } from "@util/constants";
import { Maybe, Query, SanityProvider } from "@graphql-types";

const Wrapper = styled(Container)<{
  height?: string;
}>`
  width: 100%;
  padding: 110px 0;
  background-color: ${colors.lightBlue};
`;

const ServiceProvidersBlock = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        licensedServiceProviders {
          _key
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
          files {
            asset {
              url
              _id
              originalFilename
            }
          }
          title
          images {
            image {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  ...GatsbySanityImageFluid
                }
              }
            }
            url
            _key
          }
        }
      }
    }
  `);

  const Provider = (props: Maybe<SanityProvider>) => {
    if (props == null) {
      return null;
    }
    return (
      <Container flexDirection="column">
        {props.images && props.images.length > 0 ? (
          <Container flexDirection="column">
            {props.images.map((imageLink) => {
              if (imageLink == null) {
                return null;
              }

              return (
                <A
                  key={imageLink._key}
                  margin="0 0 10px 0"
                  href={imageLink.url ?? "/"}
                  target="_blank"
                >
                  <Image
                    objectFit="contain"
                    fluid={imageLink.image?.asset?.fluid as FluidObject}
                  />
                </A>
              );
            })}
          </Container>
        ) : (
          <H3 margin="0">{props.title}</H3>
        )}
        {props.description && (
          <BlocksContent blocks={props.description._rawColumnContent} />
        )}
        {props.files &&
          props.files.length > 0 &&
          props.files.map((file) => (
            <Link
              download
              newwindow
              isUnderlined
              key={file?.asset?._id}
              linktext={file?.asset?.originalFilename}
              url={file?.asset?.url}
              color="darkBlue"
            />
          ))}
      </Container>
    );
  };

  return (
    <Wrapper>
      <Container width="70%" margin="auto" flexDirection="column">
        <Container margin=" 0 0 40px 0" flexDirection="column">
          <H2 margin="0">Licensed Service Providers</H2>
          <Separator width="515px" />
        </Container>
        <GridContainer
          repeat={5}
          mobileRepeat={1}
          rowGap="30px"
          columnGap="50px"
        >
          {sanityHomePage?.licensedServiceProviders?.map((provider) => {
            // @ts-ignore
            return <Provider key={provider?._key} {...provider} />;
          })}
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default ServiceProvidersBlock;
