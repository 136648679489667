import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@sub";
import {
  Container,
  GridContainer,
  H2,
  H3,
  P,
  VerticalSeparator,
} from "@util/standard";
import { Maybe, Query, SanityConsultations } from "@graphql-types";

const Wrapper = styled(Container)<{
  height?: string;
}>`
  width: 100%;
  padding: 110px 0;
`;

const ConsultationsBlock = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        featuredConsultations {
          title
          file {
            asset {
              url
            }
          }
          description
          link {
            url
            newwindow
            linktext
            internallink
            _key
          }
          _id
        }
      }
    }
  `);

  const Consul = (props: Maybe<SanityConsultations>) => {
    if (props == null) {
      return null;
    }
    return (
      <Container flexDirection="column">
        <H3 margin="0">{props.title}</H3>
        <P>{props.description}</P>

        <Container justifyContent="space-between">
          {props.link && props.link.linktext && (
            <Link isUnderlined {...props.link} color="darkBlue" opacity={0.5} />
          )}
          <Link
            download
            newwindow
            isUnderlined
            linktext="Download"
            url={props.file?.asset?.url}
            color="darkBlue"
            opacity={0.5}
          />
        </Container>
      </Container>
    );
  };

  return (
    <Wrapper>
      <Container width="70%" margin="auto" flexDirection="column">
        <Container margin=" 0 0 40px 0">
          <VerticalSeparator />
          <H2 margin="0">Consultations</H2>
        </Container>
        <GridContainer
          repeat={3}
          mobileRepeat={1}
          rowGap="30px"
          columnGap="50px"
        >
          {sanityHomePage?.featuredConsultations?.map((consul) => {
            // @ts-ignore
            return <Consul key={consul?._id} {...consul} />;
          })}
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default ConsultationsBlock;
